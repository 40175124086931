// src/utils/Queue.js

export default class Queue {
  constructor(maxSize) {
    this.queue = [];
    this.maxSize = maxSize;
  }

  enqueue(item) {
    if (this.queue.length >= this.maxSize) {
      this.queue.shift(); // Remove the oldest item if queue exceeds max size
    }
    this.queue.push(item);
  }

  getMedian() {
    if (this.queue.length === 0) return null;

    const sorted = [...this.queue].sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);

    return sorted.length % 2 !== 0
      ? sorted[mid]
      : (sorted[mid - 1] + sorted[mid]) / 2;
  }
}
