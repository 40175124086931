import React, { useState, useEffect } from 'react';

function DeviceCard({ device, onClick, actionText, heartRate, customName, onUpdateDeviceName, heartRateQueue, onResetQueue }) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(customName || device.name);

  useEffect(() => {
    setName(customName || device.name);
  }, [customName, device.name]);

  const handleEditClick = () => setIsEditing(true);
  const handleSaveClick = () => {
    setIsEditing(false);
    onUpdateDeviceName(name);
  };

  const getHeartRateColor = () => {
    if (!heartRateQueue || heartRateQueue.queue.length === 0) {
      console.log(`Queue is empty for device ${device.id}`);
      return 'gray';
    }

    const median = heartRateQueue.getMedian();
    console.log(`Current median for device ${device.id}: ${median}`);
    
    if (heartRate <= median + 7) return 'gray';
    if (heartRate > median + 14) return 'red';
    return 'orange';
  };

  return (
    <div>
      {isEditing ? (
        <>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button onClick={handleSaveClick}>Save</button>
        </>
      ) : (
        <>
          <p>{name} <span onClick={handleEditClick} style={{ cursor: 'pointer' }}>✏️</span></p>
        </>
      )}
      {heartRate !== undefined && (
        <p style={{ color: getHeartRateColor() }}>
          Heart Rate: {heartRate} BPM 
          <button
            onClick={() => onResetQueue(device.id)}
            style={{
              cursor: 'pointer',
              marginLeft: '10px',
              background: 'none',
              border: 'none',
              color: 'inherit',
              fontSize: '1em'
            }}
            title="Reset Queue"
          >
            ♻️
          </button>
        </p>
      )}
      <button onClick={onClick}>{actionText}</button>
    </div>
  );
}

export default DeviceCard;
