// src/utils/bluetooth.js

export async function scanForDevices() {
  try {
    console.log("Starting scan...");
    const options = {
      filters: [{ namePrefix: 'TICKR' }],
      optionalServices: ['heart_rate']
    };
    const device = await navigator.bluetooth.requestDevice(options);
    console.log(`Device selected: ${device.name}`);
    return device;
  } catch (error) {
    console.error('Error scanning for devices:', error);
    return null;
  }
}

// Connect to a device with retry logic and call a callback with heart rate data
export async function connectToDevice(device, onHeartRateUpdate, retries = 3) {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      console.log(`Attempt ${attempt} to connect to device: ${device.name}`);
      const server = await device.gatt.connect();
      console.log(`Connected to GATT server of device: ${device.name}`);
      
      // Get the heart rate service
      const service = await server.getPrimaryService('heart_rate');
      console.log(`Heart rate service found for device: ${device.name}`);
      
      // Get the heart rate measurement characteristic
      const characteristic = await service.getCharacteristic('heart_rate_measurement');
      console.log(`Heart rate characteristic found, setting up notifications for device: ${device.name}`);

      // Set up notifications and update heart rate using the callback
      characteristic.addEventListener('characteristicvaluechanged', (event) => {
        const heartRate = parseHeartRate(event.target.value);
        if (onHeartRateUpdate) {
          onHeartRateUpdate(heartRate); // Call callback with new heart rate
        }
      });

      // Start notifications
      await characteristic.startNotifications();
      console.log(`Notifications started for device: ${device.name}`);
      
      return device; // Return the connected device if successful
    } catch (error) {
      console.error(`Error connecting to device on attempt ${attempt}:`, error);
      if (attempt === retries) {
        console.error(`Failed to connect to ${device.name} after ${retries} attempts.`);
        return null; // Return null if all retries fail
      }
      await new Promise(res => setTimeout(res, 1000));
    }
  }
}

// Disconnect from a device
export function disconnectDevice(device) {
  if (device.gatt && device.gatt.connected) {
    device.gatt.disconnect();
    console.log(`Disconnected from ${device.name}`);
  } else {
    console.log(`${device.name} was already disconnected.`);
  }
}

// Parse heart rate from characteristic value
function parseHeartRate(value) {
  const flags = value.getUint8(0);
  const rate16Bits = flags & 0x1;
  return rate16Bits ? value.getUint16(1, true) : value.getUint8(1);
}
