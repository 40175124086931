import React, { useState } from 'react';
import DeviceList from './components/DeviceList';
import Queue from './utils/Queue';
import { scanForDevices, connectToDevice, disconnectDevice } from './utils/bluetooth';

function App() {
  const [availableDevices, setAvailableDevices] = useState([]);
  const [connectedDevices, setConnectedDevices] = useState([]);
  const [deviceHeartRates, setDeviceHeartRates] = useState({});
  const [heartRateQueues, setHeartRateQueues] = useState({});
  const [discoveredDevices, setDiscoveredDevices] = useState([]);
  const [deviceNames, setDeviceNames] = useState({});

  const handleScan = async () => {
    const device = await scanForDevices();
    if (device) {
      const isAlreadyDiscovered = discoveredDevices.some(d => d === device.id);
      if (!isAlreadyDiscovered) {
        setAvailableDevices(prevDevices => [...prevDevices, device]);
        setDiscoveredDevices(prevDevices => [...prevDevices, device.id]);
      }
    }
  };

  const handleConnectDevice = async (device) => {
    const connectedDevice = await connectToDevice(device, (heartRate) => {
      setHeartRateQueues(prevQueues => {
        const newQueues = { ...prevQueues };
        if (!newQueues[device.id]) {
          newQueues[device.id] = new Queue(100);
        }
        newQueues[device.id].enqueue(heartRate);
        return newQueues;
      });

      setDeviceHeartRates(prevRates => ({
        ...prevRates,
        [device.id]: heartRate
      }));
    });

    if (connectedDevice) {
      setConnectedDevices([...connectedDevices, connectedDevice]);
      setAvailableDevices(availableDevices.filter(d => d.id !== device.id));
    }
  };

  const handleDisconnectDevice = (device) => {
    disconnectDevice(device);
    setConnectedDevices(connectedDevices.filter(d => d.id !== device.id));
    setAvailableDevices(prevDevices => [...prevDevices, device]);
    setDiscoveredDevices(prevDevices => prevDevices.filter(id => id !== device.id));

    setDeviceNames(prevNames => {
      const { [device.id]: _, ...remainingNames } = prevNames;
      return remainingNames;
    });

    setDeviceHeartRates(prevRates => {
      const { [device.id]: _, ...remainingRates } = prevRates;
      return remainingRates;
    });

    setHeartRateQueues(prevQueues => {
      const { [device.id]: _, ...remainingQueues } = prevQueues;
      return remainingQueues;
    });
  };

  const updateDeviceName = (deviceId, name) => {
    setDeviceNames(prevNames => ({
      ...prevNames,
      [deviceId]: name
    }));
  };

  // Reset the heart rate queue for a specific device
  const resetQueue = (deviceId) => {
    console.log(`Resetting queue for device: ${deviceId}`);
    setHeartRateQueues(prevQueues => ({
      ...prevQueues,
      [deviceId]: new Queue(100) // Replace with a new empty queue
    }));
  };

  return (
    <div className="App">
      <h1>BLE Heart Monitor</h1>
      <button onClick={handleScan}>Start Scan</button>
      <DeviceList
        availableDevices={availableDevices}
        connectedDevices={connectedDevices}
        deviceHeartRates={deviceHeartRates}
        deviceNames={deviceNames}
        heartRateQueues={heartRateQueues}
        onConnectDevice={handleConnectDevice}
        onDisconnectDevice={handleDisconnectDevice}
        onUpdateDeviceName={updateDeviceName}
        onResetQueue={resetQueue} // Pass the reset function to DeviceList
      />
    </div>
  );
}

export default App;
