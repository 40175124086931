import React from 'react';
import DeviceCard from './DeviceCard';

function DeviceList({
  availableDevices,
  connectedDevices,
  deviceHeartRates,
  deviceNames,
  heartRateQueues,
  onConnectDevice,
  onDisconnectDevice,
  onUpdateDeviceName,
  onResetQueue // Receive onResetQueue from App
}) {
  return (
    <div>
      <h2>Available Devices</h2>
      {availableDevices.map(device => (
        <DeviceCard
          key={device.id}
          device={device}
          actionText="Connect"
          onClick={() => onConnectDevice(device)}
          customName={deviceNames[device.id] || device.name}
        />
      ))}
      <h2>Connected Devices</h2>
      {connectedDevices.map(device => (
        <DeviceCard
          key={device.id}
          device={device}
          actionText="Disconnect"
          onClick={() => onDisconnectDevice(device)}
          heartRate={deviceHeartRates[device.id]}
          customName={deviceNames[device.id] || device.name}
          heartRateQueue={heartRateQueues[device.id]}
          onUpdateDeviceName={(name) => onUpdateDeviceName(device.id, name)}
          onResetQueue={onResetQueue} // Pass to DeviceCard
        />
      ))}
    </div>
  );
}

export default DeviceList;
